// Dependencies
import React from "react"
// Components
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout title="404: Not found" notFound />
)

export default NotFoundPage
